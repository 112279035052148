import { captureException, setUser } from '@sentry/nextjs';
import { addSeconds, differenceInSeconds, isAfter } from 'date-fns';
import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import store2 from 'store2';

import { refreshToken } from '../services/userService';

export interface OAuthData {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  created_at: number;
  resource_owner_global_id: string;
  resource_owner_email: string;
}

export interface AuthContextValue {
  isReady: boolean;
  accessToken: string;
  setAccessToken: (token: string) => void;
  setOAuthData: (token: OAuthData) => void;
  gymId: string;
  setGymId: (gymId: string) => void;
}

const AuthContext = createContext<AuthContextValue>({
  isReady: false,
  accessToken: '',
  setAccessToken: () => '',
  setOAuthData: () => '',
  gymId: '',
  setGymId: () => '',
});

export const AuthContextProvider: FC = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [oAuthData, setOAuthData] = useState<OAuthData>();
  const [gymId, setGymId] = useState('');
  const rtTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const mountedRef = useRef(false);

  useEffect(() => {
    if (
      oAuthData?.resource_owner_global_id &&
      oAuthData?.resource_owner_email
    ) {
      setUser({
        id: oAuthData?.resource_owner_global_id,
        email: oAuthData?.resource_owner_email,
      });
    } else {
      setUser(null);
    }
  }, [oAuthData?.resource_owner_email, oAuthData?.resource_owner_global_id]);

  useEffect(() => {
    if (isReady) {
      store2.set('oAuthData', oAuthData);
      const storedOAuthData = store2.get('oAuthData');
      setAccessToken(storedOAuthData?.access_token);

      if (storedOAuthData) {
        const expiredAt = addSeconds(
          new Date(storedOAuthData.created_at * 1000),
          storedOAuthData.expires_in
        );

        const MAX_TIMEOUT = 2147483647; // ~24.8 days in milliseconds

        const timeoutTime = Math.min(
          MAX_TIMEOUT,
          (differenceInSeconds(expiredAt, new Date()) - 60) * 1000
        );

        rtTimeoutRef.current = setTimeout(async () => {
          if (!storedOAuthData.refresh_token) return;

          const refreshedOAuthData = await refreshToken(
            storedOAuthData.refresh_token
          );

          if (mountedRef.current) {
            setOAuthData(refreshedOAuthData);
          }
        }, timeoutTime);
      }
    }

    return () => {
      clearTimeout(rtTimeoutRef.current);
    };
  }, [isReady, oAuthData]);

  useEffect(() => {
    if (!gymId || gymId === 'undefined' || gymId === 'null') return;

    store2.set('gymId', gymId);
  }, [gymId]);

  useEffect(() => {
    mountedRef.current = true;

    (async () => {
      if (process.browser) {
        try {
          let oAuthData: OAuthData = store2.get('oAuthData');
          const gymId = store2.get('gymId');

          const expiredAt = addSeconds(
            new Date(oAuthData.created_at * 1000),
            oAuthData.expires_in
          );

          if (isAfter(new Date(), expiredAt) && oAuthData.refresh_token) {
            oAuthData = await refreshToken(oAuthData.refresh_token);
          }

          setOAuthData(oAuthData);
          setAccessToken(oAuthData.access_token);
          gymId && setGymId(gymId);
        } catch (error) {
          captureException(error);
        }

        setIsReady(true);
      }
    })();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const value = useMemo(
    () => ({
      isReady,
      accessToken,
      setAccessToken,
      setOAuthData,
      gymId,
      setGymId,
    }),
    [accessToken, gymId, isReady]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthContextProvider.`);
  }
  return context;
};

export const getAccessToken = (): string => {
  try {
    const oAuthData: OAuthData = store2.get('oAuthData');

    return oAuthData?.access_token;
  } catch (error) {
    captureException(error);
  }
};

export const getGymId = (): string => {
  try {
    return store2.get('gymId');
  } catch (error) {
    captureException(error);
  }
};
